import { ChevronLeftIcon, HomeIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const pages = [{ name: 'Settings', href: '#' }]

type Props = {
  current: string
  previous?: {
    to: string
    title: string
  }[]
  children?: React.ReactNode
}

export default function Breadcrumb(props: Props) {
  const seperator = (
    <svg
      className="flex-shrink-0 h-5 w-5 text-gray-300"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
    </svg>
  )

  return (
    <>
      {props.current === 'Dashboard' && <></>}
      {props.current !== 'Dashboard' && (
        <>
          <nav className="sm:hidden" aria-label="Back">
            <Link
              to="/"
              className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              <ChevronLeftIcon
                className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Back</span>
            </Link>
          </nav>
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </li>
              {props.previous &&
                props.previous.map((page) => (
                  <li key={page.title}>
                    <div className="flex items-center">
                      {seperator}
                      <Link
                        to={page.to}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        {page.title}
                      </Link>
                    </div>
                  </li>
                ))}
              <li>
                <div className="flex items-center">
                  {seperator}
                  <span
                    className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-900"
                    aria-current="page"
                  >
                    {props.current}
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </>
      )}
    </>
  )
}
