import { Outlet } from 'react-router-dom'
import Sidenav from './Sidenav'
import clsx from 'clsx'
import Container from './ui-kit/Container'
import { SparklesIcon } from '@heroicons/react/20/solid'

export default function InternalLayout() {
  return (
    <>
      <div>
        <Sidenav />
        <div className="lg:pl-20 relative flex flex-col justify-between min-h-screen">
          <main className="py-10 lg:py-8">
            <Container>
              <div className="space-y-6">
                <Outlet />
              </div>
            </Container>
          </main>
          <footer className={clsx('py-2', true && 'hidden md:block')}>
            <Container>
              <div className="flex flex-col md:flex-row justify-between items-center">
                <ul role="list" className="flex items-center space-x-2">
                  <li>
                    <a
                      href={`https://magicforms.io/legal/`}
                      target="_blank"
                      rel="noopener"
                      className="text-xs leading-6 text-gray-500 hover:text-gray-900"
                    >
                      Legal
                    </a>
                  </li>
                  <li className="text-gray-500" aria-hidden="true">
                    &middot;
                  </li>
                  <li>
                    <a
                      href={`https://magicforms.io/terms/`}
                      target="_blank"
                      rel="noopener"
                      className="text-xs leading-6 text-gray-500 hover:text-gray-900"
                    >
                      Terms
                    </a>
                  </li>
                  <li className="text-gray-500" aria-hidden="true">
                    &middot;
                  </li>
                  <li>
                    <a
                      href={`https://magicforms.io/privacy/`}
                      target="_blank"
                      rel="noopener"
                      className="text-xs leading-6 text-gray-500 hover:text-gray-900"
                    >
                      Privacy
                    </a>
                  </li>
                </ul>
                <ul role="list" className="flex items-center space-x-2">
                  <li>
                    <a
                      href={`https://magicforms.io/changelog/`}
                      target="_blank"
                      rel="noopener"
                      className="text-xs leading-6 text-gray-500 hover:text-gray-900"
                    >
                      v0.0.1
                    </a>
                  </li>
                  <li className="text-gray-500" aria-hidden="true">
                    &middot;
                  </li>
                  <li>
                    <span className="flex items-center text-xs leading-6 text-gray-500">
                      © {new Date().getFullYear()}, made with
                      <span className="sr-only">magic</span>
                      <SparklesIcon className="mx-1 w-4 h-4 text-indigo-600" />
                      in Germany
                    </span>
                  </li>
                </ul>
              </div>
            </Container>
          </footer>
        </div>
      </div>
    </>
  )
}
