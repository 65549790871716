import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import ForgotPasswordRequest from 'src/business/dto/requests/forgot-password.requst'
import { updatePageTitle } from 'src/business/utils'
import { forgotPassword } from 'src/business/api/auth.service'
import UiToast from 'src/components/ui-kit/UiToast'
import { handleValidationError } from 'src/business/error-handler'
import Button from 'src/components/ui-kit/Button'
import Input from 'src/components/ui-kit/Input'
import { SparklesIcon } from '@heroicons/react/24/solid'
import VerticalAlignment from 'src/components/ui-kit/VerticalAlignment'
import FormContainer from 'src/components/ui-kit/FormContainer'

const ForgotPasswordPage = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [requested, setRequested] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [resendLoading, setResendLoading] = useState<boolean>(false)
  const form = useForm<ForgotPasswordRequest>({
    defaultValues: {
      email: searchParams.get('email') || (location.state as any)?.email || '',
    },
  })
  const formEmail = form.watch('email')

  useEffect(() => {
    updatePageTitle(t('page.title.forgotPassword'))
  }, [])

  const onSubmit: SubmitHandler<ForgotPasswordRequest> = (data) => {
    setLoading(true)
    forgotPassword(data)
      .then((_) => {
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.emailSent')}
            description={t('toast.description.resetPasswordTokenSent')}
          />
        ))
        setRequested(true)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  const onResendForgotPassword = () => {
    setResendLoading(true)
    forgotPassword({
      email: formEmail,
    })
      .then((_) => {
        setResendLoading(false)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.emailResent')}
            description={t('toast.description.resetPasswordTokenSent')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  return (
    <>
      {!requested && (
        <div className="space-y-10">
          <div>
            {false && (
              <img
                className="h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              />
            )}
            <SparklesIcon className="h-10 w-10 text-indigo-600" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {t('page.title.forgotPassword')}
            </h2>
            <p className="mt-2 text-base leading-6 text-gray-500">
              {t('page.description.forgotPassword')}
            </p>
          </div>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <VerticalAlignment>
              <FormContainer>
                <Input
                  label={t('common.emailAddress')}
                  type="text"
                  autoComplete="email"
                  autoFocus
                  error={form.formState.errors.email}
                  {...form.register('email', {
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: t('form.validation.emailAddress'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  })}
                />
              </FormContainer>
              <Button
                type="submit"
                variant="primary"
                className="w-full"
                loading={loading}
              >
                {t('action.sendResetLink')}
              </Button>
            </VerticalAlignment>
          </form>
          <div className="pt-6 text-center border-t border-gray-200">
            <p className="text-sm leading-6 text-gray-500">
              <span>{t('description.alreadyHaveAnAccount')} </span>
              <Link
                to="/sign-in"
                className="font-medium text-indigo-600 hover:text-indigo-700"
              >
                {t('action.goToSignIn')}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        </div>
      )}
      {requested && (
        <div className="space-y-10">
          <div>
            {false && (
              <img
                className="h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              />
            )}
            <SparklesIcon className="h-10 w-10 text-indigo-600" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {t('page.title.forgotPassword2')}
            </h2>
            <p
              className="mt-2 text-base leading-6 text-gray-500"
              dangerouslySetInnerHTML={{
                __html: t('page.description.forgotPassword2', {
                  email: formEmail,
                }),
              }}
            />
          </div>
          <div className="text-sm leading-6 text-gray-500">
            {t('description.noEmailReceived')}
            <ul className="pl-4 list-disc">
              <li>{t('description.waitAFewMinutes')}</li>
              <li>{t('description.lookInYourSpam')}</li>
              <li>
                <button
                  className="font-medium text-indigo-600 hover:text-indigo-700 disabled:text-gray-400 disabled:cursor-not-allowed"
                  onClick={onResendForgotPassword}
                  disabled={resendLoading}
                >
                  {t('action.resendEmail')}
                </button>{' '}
                (<span className="font-medium">{formEmail}</span>)
              </li>
              <li>
                <a
                  className="font-medium text-indigo-600 hover:text-indigo-700"
                  href="mailto:support@resflow.com"
                >
                  {t('action.contactSupport')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default ForgotPasswordPage
