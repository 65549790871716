import Header from '../components/Header'
import Tabs from 'src/components/ui-kit/Tabs'
import { Outlet } from 'react-router-dom'

export default function AccountPage() {
  return (
    <>
      <Header
        title="Account"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean turpis mi, rhoncus nec dapibus in, rutrum at leo."
      />
      <Tabs
        tabs={[
          { title: 'Personal', to: '/account' },
          {
            title: 'Security',
            to: '/account/security',
          },
          {
            title: 'Plan & Billing',
            to: '/account/billing',
          },
        ]}
      />
      <Outlet />
    </>
  )
}
