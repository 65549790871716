type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  children: React.ReactNode
}

export default function FormContainer(props: Props) {
  return (
    <div className="w-full sm:max-w-sm">
      <div className="space-y-4">{props.children}</div>
    </div>
  )
}
