import VerticalAlignment from './VerticalAlignment'

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  children: React.ReactNode
}

export default function CardContainer(props: Props) {
  return (
    <div className="w-full max-w-4xl">
      <VerticalAlignment>{props.children}</VerticalAlignment>
    </div>
  )
}
