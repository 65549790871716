import axios from 'axios'
import SignUpRequest from '../dto/requests/sign-up.request'
import SignInRequest from '../dto/requests/sign-in.request'
import JwtTokenResponse from '../dto/responses/jwt-token.response'
import ObtainTokenRequest from '../dto/requests/obtain-token.requst'
import VerificationRequest from '../dto/requests/verification.requst'
import VerifyCodeRequest from '../dto/requests/verify-code.requst'
import ForgotPasswordRequest from '../dto/requests/forgot-password.requst'
import ResetPasswordRequest from '../dto/requests/request-password.requst'
import UserResponse from '../dto/responses/user.response'
import UserPopulatedResponse from '../dto/responses/user-populated.response'
import CreateCheckoutRequest from '../dto/requests/create-checkout.request'
import CreateCustomerPortalRequest from '../dto/requests/create-customer-portal.request'
import Quota from '../dto/responses/quota'

const ressourceUrl = `/auth`

export const signUp = async (data: SignUpRequest): Promise<void> => {
  return axios
    .post(`${ressourceUrl}/sign-up`, data)
    .then((response) => response.data)
}

export const signIn = async (
  data: SignInRequest
): Promise<JwtTokenResponse> => {
  return axios
    .post(`${ressourceUrl}/sign-in`, data)
    .then((response) => response.data)
}

export const obtainToken = async (
  data: ObtainTokenRequest
): Promise<JwtTokenResponse> => {
  return axios
    .post(`${ressourceUrl}/token`, data)
    .then((response) => response.data)
}

export const createVerification = async (
  data: VerificationRequest
): Promise<void> => {
  return axios
    .post(`${ressourceUrl}/verification`, data)
    .then((response) => response.data)
}

export const verifyCodeAndSignIn = async (
  data: VerifyCodeRequest
): Promise<JwtTokenResponse> => {
  return axios
    .post(`${ressourceUrl}/verify`, data)
    .then((response) => response.data)
}

export const forgotPassword = async (
  data: ForgotPasswordRequest
): Promise<void> => {
  return axios
    .post(`${ressourceUrl}/forgot-password`, data)
    .then((response) => response.data)
}

export const resetPassword = async (
  data: ResetPasswordRequest
): Promise<JwtTokenResponse> => {
  return axios
    .post(`${ressourceUrl}/reset-password`, data)
    .then((response) => response.data)
}

export const loadAuth = async (): Promise<UserResponse> => {
  return axios
    .get(ressourceUrl, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const loadAuthPopulated = async (): Promise<UserPopulatedResponse> => {
  return axios
    .get(ressourceUrl, {
      params: { populate: true },
    })
    .then((response) => response.data)
}

export const findQuota = async (): Promise<Quota> => {
  return axios.get(`${ressourceUrl}/quota`).then((response) => response.data)
}

export const createCheckout = async (
  data: CreateCheckoutRequest
): Promise<{ url: string }> => {
  return axios
    .post(`${ressourceUrl}/checkout`, data)
    .then((response) => response.data)
}

export const createCompanyPortal = async (
  data: CreateCustomerPortalRequest
): Promise<{ url: string }> => {
  return axios
    .post(`${ressourceUrl}/customer-portal`, data)
    .then((response) => response.data)
}
