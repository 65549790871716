import { BellIcon, PencilIcon } from '@heroicons/react/24/outline'
import Breadcrumb from './Breadcrumb'
import VerticalAlignment from './ui-kit/VerticalAlignment'

type Props = {
  title: string
  description: string
  breadcrumbs?: {
    to: string
    title: string
  }[]
  avatar?: {
    src: string
  }
  welcome?: string
  children?: React.ReactNode
}

export default function Header(props: Props) {
  return (
    <VerticalAlignment>
      <div>
        <Breadcrumb current={props.title} previous={props.breadcrumbs} />
      </div>
      <div className="md:flex md:items-center md:justify-between md:space-x-5">
        <div className="flex items-start space-x-5">
          {props.avatar && (
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full"
                  src={props.avatar.src}
                  alt=""
                />
                <span
                  className="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                />
              </div>
            </div>
          )}
          <div>
            {props.welcome && (
              <div className="text-sm font-medium text-gray-600 pb-1">
                Welcome back {props.welcome},
              </div>
            )}
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {props.title}
            </h1>
            <p className="mt-2 text-gray-500">{props.description}</p>
          </div>
        </div>

        {false && (
          <div className="mt-4 flex space-x-3 md:mt-0">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              <PencilIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Edit</span>
            </button>
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              <BellIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Subscribe</span>
            </button>
          </div>
        )}
      </div>
    </VerticalAlignment>
  )
}
