import clsx from 'clsx'
import { NavLink } from 'react-router-dom'

type Props = {
  tabs: {
    to: string
    title: string
  }[]
}

export default function Tabs(props: Props) {
  // let resolved = useResolvedPath(props.to)
  // let match = useMatch({ path: resolved.pathname, end: props.end })

  return (
    <div>
      {/* <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={props.tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div> */}
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {props.tabs.map((tab) => (
              <NavLink
                key={tab.title}
                to={tab.to}
                end={true}
                className={({ isActive, isPending }) =>
                  clsx(
                    isActive
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                  )
                }
                aria-current={true ? 'page' : undefined}
              >
                {tab.title}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
