import Button from 'src/components/ui-kit/Button'
import Card from 'src/components/ui-kit/Card'
import CardContainer from 'src/components/ui-kit/CardContainer'
import FormContainer from 'src/components/ui-kit/FormContainer'
import Input from 'src/components/ui-kit/Input'
import VerticalAlignment from 'src/components/ui-kit/VerticalAlignment'

export default function AccountSecurityPage() {
  return (
    <CardContainer>
      <Card
        title="Update your password"
        description="Change the password associated with your account."
      >
        <VerticalAlignment>
          <FormContainer>
            <Input name="password" type="password" label="Password" />
            <Input
              name="confirm-password"
              type="password"
              label="Confirm password"
            />
          </FormContainer>
          <Button variant="primary">Save changes</Button>
        </VerticalAlignment>
      </Card>
    </CardContainer>
  )
}
