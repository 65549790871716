import { PlusCircleIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useState } from 'react'

export default function FormPagesPage() {
  const people = [
    {
      name: 'Corenox Projektanfrage',
      title: 'Edited 12 minutes ago',
      role: 'active',
      email: 'janecooper@example.com',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      name: 'Corenox Projektanfrage',
      title: 'Edited 12 minutes ago',
      role: 'active',
      email: 'janecooper@example.com',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      name: 'Corenox Projektanfrage',
      title: 'Edited 12 minutes ago',
      role: 'active',
      email: 'janecooper@example.com',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      name: 'Corenox Projektanfrage',
      title: 'Edited 12 minutes ago',
      role: 'active',
      email: 'janecooper@example.com',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      name: 'Corenox Projektanfrage',
      title: 'Edited 12 minutes ago',
      role: 'active',
      email: 'janecooper@example.com',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      name: 'Corenox Projektanfrage',
      title: 'Edited 12 minutes ago',
      role: 'active',
      email: 'janecooper@example.com',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    // More people...
  ]

  const [activeQuestion, setActiveQuestion] = useState<number | null>(null)
  const [activeAnswer, setActiceAnswer] = useState<number | null>(null)

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-8 space-y-6">
        {Array.from({ length: 6 }).map((_, slide) => (
          <div
            className={clsx(
              'relative p-16 border-2 border-gray-300 rounded-lg',
              activeQuestion === slide
                ? 'border-indigo-600 bg-indigo-50'
                : 'border-gray-200'
            )}
          >
            <small className="absolute top-6 left-6 text-gray-400 font-semibold">
              Step {slide + 1}
            </small>
            <a
              type="button"
              onClick={() => {
                setActiveQuestion(slide)
                setActiceAnswer(null)
              }}
              className="absolute inset-0 z-10 cursor-pointer rounded-md"
              aria-hidden="true"
            />
            <div className="space-y-10">
              <div className="text-center max-w-prose mx-auto">
                <div className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">
                  Willkommen in der Kluft der Beschwörer
                </div>
                <p className="mt-2 text-gray-500">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor ut labore et dolore magna aliquyam
                  erat, sed diam voluptua.{' '}
                </p>
              </div>
              <div className="mx-auto w-full max-w-3xl">
                <div className="-m-2 flex flex-wrap items-center justify-center">
                  {people.map((person, index) => (
                    <div className="p-2 sm:w-1/2 lg:w-4/12">
                      <div className="relative overflow-hidden rounded-md shadow">
                        <div
                          className={`w-full border-2 rounded-md py-3 px-4 text-left focus:outline-none ${
                            activeAnswer === slide * 100 + index
                              ? `border-indigo-600 bg-indigo-50`
                              : 'border-transparent bg-white hover:bg-gray-50'
                          }`}
                        >
                          <div className="flex flex-col items-center space-y-4">
                            <img
                              className="w-14 h-14"
                              srcSet="https://images.pexels.com/photos/3775128/pexels-photo-3775128.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                            />
                            <div className="text-center">
                              <h3 className="text font-medium text-gray-900">
                                Hallo
                              </h3>
                              <dl className="mt-1 flex flex-grow flex-col justify-between">
                                <dt className="sr-only">Title</dt>
                                <dd className="text-sm text-gray-500">
                                  Description
                                </dd>
                              </dl>
                            </div>
                          </div>
                          <a
                            type="button"
                            onClick={() => {
                              setActiveQuestion(null)
                              setActiceAnswer(slide * 100 + index)
                            }}
                            className="absolute inset-0 z-10 cursor-pointer rounded-md"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div>
          <button
            type="button"
            className="relative block w-full h-32 rounded-lg border-2 border-dashed border-gray-300 px-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
            <span className="mt-2 block text-sm font-semibold text-gray-900">
              New Step
            </span>
          </button>
        </div>
      </div>
      <div className="col-span-4">
        <div className="sticky top-6">
          <div className="bg-white shadow sm:rounded-lg max-h-[calc(100vh-16rem)] overflow-y-auto">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Update your account
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  Change the email address you want associated with your
                  account.
                </p>
              </div>
              <form className="mt-5">
                <div className="space-y-4">
                  <div className="w-full sm:max-w-xs">
                    <label
                      htmlFor="photo"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Photo
                    </label>
                    <div className="mt-2 flex items-center gap-x-3">
                      <UserCircleIcon
                        className="h-12 w-12 text-gray-300"
                        aria-hidden="true"
                      />
                      <button
                        type="button"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Change
                      </button>
                    </div>
                  </div>

                  <div className="w-full sm:max-w-xs">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="name"
                      />
                    </div>
                  </div>

                  {Array.from({ length: 16 }).map((_, slide) => (
                    <div className="w-full sm:max-w-xs">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="you@example.com"
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  type="submit"
                  className="mt-6 inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
