import Header from '../../components/Header'
import {
  Form,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
} from 'react-router-dom'
import { Project } from 'src/business/dto/project'
import Tabs from 'src/components/ui-kit/Tabs'

export async function loader({ params }: LoaderFunctionArgs) {
  // const project = await getProject(params.projectId!)
  // return { project }
}

export default function ProjectPage() {
  const { project } = useLoaderData() as { project: Project }

  return (
    <>
      <Header
        title="Corenox"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean turpis mi, rhoncus nec dapibus in, rutrum at leo."
        breadcrumbs={[{ to: '/projects', title: 'Projects' }]}
      />
      <Tabs
        tabs={[
          { title: 'Forms (2)', to: '/projects/1/forms' },
          { title: 'Users (6)', to: '/projects/1/users' },
          { title: 'Settings', to: '/projects/1/settings' },
        ]}
      />
      <Outlet />

      <Form
        method="post"
        action="destroy"
        // onSubmit={(event) => {
        //   if (!confirm('Please confirm you want to delete this record.')) {
        //     event.preventDefault()
        //   }
        // }}
      >
        <button type="submit">Delete</button>
      </Form>
    </>
  )
}
