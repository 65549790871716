import { useLoaderData, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { loadAuth } from 'src/business/api/auth.service'
import UserResponse from 'src/business/dto/responses/user.response'
import AuthContext from 'src/contexts/auth-context'

export const authLoader = async () => {
  const user = await loadAuth()

  // if (authUser && authUser.language !== i18n.language) {
  //   i18n.changeLanguage(company.localization.language)
  // }

  return { user }
}

type Props = {
  children?: React.ReactNode
}

const AuthProvider = (props: Props) => {
  const navigate = useNavigate()
  const loaderData = useLoaderData() as {
    user: UserResponse
  }
  const [user, setUser] = useState<UserResponse>(loaderData.user)

  const impersonated =
    !!localStorage.getItem('magicforms_staff_access_token') &&
    !!localStorage.getItem('magicforms_staff_refresh_token')

  const signOut = () => {
    const magicformsStaffTokenType = localStorage.getItem(
      'magicforms_staff_token_type'
    )
    const magicformsStaffAccessToken = localStorage.getItem(
      'magicforms_staff_access_token'
    )
    const magicformsStaffrefreshToken = localStorage.getItem(
      'magicforms_staff_refresh_token'
    )

    localStorage.removeItem('magicforms_staff_token_type')
    localStorage.removeItem('magicforms_staff_access_token')
    localStorage.removeItem('magicforms_staff_refresh_token')

    if (
      magicformsStaffTokenType &&
      magicformsStaffAccessToken &&
      magicformsStaffrefreshToken
    ) {
      localStorage.setItem('token_type', magicformsStaffTokenType)
      localStorage.setItem('access_token', magicformsStaffAccessToken)
      localStorage.setItem('refresh_token', magicformsStaffrefreshToken)
    } else {
      localStorage.removeItem('token_type')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
    }

    navigate('/sign-in', { replace: true })
    // toast.custom((t) => (
    //   <UiToast
    //     toast={t}
    //     type="success"
    //     title={i18n.t('toast.title.signedOut')}
    //     description={i18n.t('toast.description.signedOut')}
    //   />
    // ))
  }

  // useEffect(() => {
  //   if (company && company.localization.language !== i18n.language) {
  //     i18n.changeLanguage(company.localization.language)
  //   }
  // }, [company])

  return (
    <>
      <AuthContext.Provider
        value={{
          user,
          setUser,
          signOut,
          impersonated,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    </>
  )
}

export default AuthProvider
