import { match } from '@formatjs/intl-localematcher'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { SubmitHandler, ValidateResult, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { signUp } from 'src/business/api/auth.service'
import SignUpRequest from 'src/business/dto/requests/sign-up.request'
import Language from 'src/business/dto/types/language'
import TimeZone from 'src/business/dto/types/time-zone'
import { handleValidationError } from 'src/business/error-handler'
import { updatePageTitle } from 'src/business/utils'
import Button from 'src/components/ui-kit/Button'
import FormContainer from 'src/components/ui-kit/FormContainer'
import Input from 'src/components/ui-kit/Input'
import VerticalAlignment from 'src/components/ui-kit/VerticalAlignment'
import ct from 'countries-and-timezones'
import Country from 'src/business/dto/types/country'
import Industry from 'src/business/dto/types/industry'
import { SparklesIcon } from '@heroicons/react/24/solid'

export const getCountryByZimezone = (timezone: TimeZone): Country => {
  return ct.getCountriesForTimezone(timezone)[0].id as Country
}

export default function SignUpPage() {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)

  const language = match(
    [i18n.language],
    Object.values(Language),
    'en'
  ) as Language

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZone
  const country = getCountryByZimezone(timeZone)

  const form = useForm<SignUpRequest>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      language,
      timeZone,
      country,
      industry: Industry.other,
    },
  })
  const [formName, formEmail, formPassword, formPasswordConfirmation] =
    form.watch(['name', 'email', 'password', 'passwordConfirmation'])

  useEffect(() => {
    updatePageTitle(t('page.title.signUp'))
  }, [])

  const onSubmit: SubmitHandler<SignUpRequest> = (data) => {
    setLoading(true)
    signUp(data)
      .then((_) => {
        navigate({
          pathname: '/verify',
          search: `?email=${encodeURIComponent(formEmail)}`,
        })
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <div className="space-y-10">
      <div>
        {false && (
          <img
            className="h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
        )}
        <SparklesIcon className="h-10 w-10 text-indigo-600" />
        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {t('page.title.signUp')}
        </h2>
        <p className="mt-2 text-base leading-6 text-gray-500">
          {t('page.description.signUp')}
        </p>
      </div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <VerticalAlignment>
          <FormContainer>
            <Input
              label={t('common.name')}
              type="text"
              autoComplete="name"
              autoFocus
              error={form.formState.errors.name}
              {...form.register('name', {
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                maxLength: {
                  value: 32,
                  message: t('form.validation.maxLength', { x: 32 }),
                },
              })}
            />
            <Input
              label={t('common.emailAddress')}
              type="text"
              autoComplete="email"
              error={form.formState.errors.email}
              {...form.register('email', {
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t('form.validation.emailAddress'),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              })}
            />
            <Input
              label={t('common.password')}
              type="password"
              error={form.formState.errors.password}
              {...form.register('password', {
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                minLength: {
                  value: 8,
                  message: t('form.validation.minLength', { x: 8 }),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              })}
            />
            <Input
              label={t('common.passwordConfirmation')}
              type="password"
              error={form.formState.errors.passwordConfirmation}
              {...form.register('passwordConfirmation', {
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                validate: (value: string | undefined): ValidateResult => {
                  return (
                    value === formPassword ||
                    t('form.validation.passwordsDoNotMatch')!
                  )
                },
              })}
            />
          </FormContainer>
          <p className="text-sm leading-6 text-gray-500">
            <Trans
              i18nKey="form.description.privacyAndTerms"
              components={{
                privacyUrl: (
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-700"
                    href={`https://magicforms.io/${i18n.language}/${t(
                      'href.terms'
                    )}/`}
                    target="_blank"
                    rel="noopener"
                  />
                ),
                termsUrl: (
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-700"
                    href={`https://magicforms.io/${i18n.language}/${t(
                      'href.privacy'
                    )}/`}
                    target="_blank"
                    rel="noopener"
                  />
                ),
              }}
            />
          </p>
          <Button
            variant="primary"
            type="submit"
            className="w-full"
            loading={loading}
          >
            {t('action.signUp')}
          </Button>
        </VerticalAlignment>
      </form>
      <div className="pt-6 text-center border-t border-gray-200">
        <p className="text-sm leading-6 text-gray-500">
          <span>{t('description.alreadyHaveAnAccount')} </span>
          <Link
            to="/sign-in"
            className="font-medium text-indigo-600 hover:text-indigo-700"
          >
            {t('action.goToSignIn')}
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </p>
      </div>
    </div>
  )
}
