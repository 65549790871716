import VerticalAlignment from './VerticalAlignment'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode
  title: string
  description: string
}

export default function Card(props: Props) {
  return (
    <>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {props.title}
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>{props.description}</p>
          </div>
          <div className="mt-5">{props.children}</div>
        </div>
      </div>
    </>
  )
}
