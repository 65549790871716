import { ChangeEvent, ClipboardEvent, useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { updatePageTitle } from 'src/business/utils'
import {
  createVerification,
  verifyCodeAndSignIn,
} from 'src/business/api/auth.service'
import UiToast from 'src/components/ui-kit/UiToast'
import { handleValidationError } from 'src/business/error-handler'
import Button from 'src/components/ui-kit/Button'
import { SparklesIcon } from '@heroicons/react/24/solid'
import VerticalAlignment from 'src/components/ui-kit/VerticalAlignment'
import FormContainer from 'src/components/ui-kit/FormContainer'

const VerifyPage = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [resendLoading, setResendLoading] = useState<boolean>(false)
  const itemsRef = useRef<Array<HTMLInputElement | null>>([])
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [numbers, setNumbers] = useState<string[]>(() => {
    const initialValue = `${searchParams.get('code')}`
    let chars = initialValue?.replace(/[^0-9.]/g, '')

    const result: string[] = []
    for (let i = 0; i < 6; i++) {
      if (chars && chars.charAt(i)) {
        result.push(chars.charAt(i))
      } else {
        result.push('')
      }
    }
    return result
  })

  useEffect(() => {
    updatePageTitle(t('page.title.verify'))

    if (
      !localStorage.getItem('token_type') &&
      numbers.filter((x) => x !== '').length === 6
    ) {
      onSubmit()
    }
  }, [])

  const onSubmit = () => {
    buttonRef.current?.focus()
    setLoading(true)

    setTimeout(() => {
      verifyCodeAndSignIn({
        email: searchParams.get('email')!,
        verificationCode: numbers.join(''),
      })
        .then((response) => {
          localStorage.setItem('token_type', response.tokenType)
          localStorage.setItem('access_token', response.accessToken)
          localStorage.setItem('refresh_token', response.refreshToken)
          navigate('/', { replace: true })
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type="success"
              title={t('toast.title.verified')}
              description={t('toast.description.accountVerified')}
            />
          ))
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            toast.custom((toast) => (
              <UiToast
                toast={toast}
                type="error"
                title={t('toast.title.badRequest')}
                description={t('toast.description.codeNotValid')}
              />
            ))
          } else {
            handleValidationError(err, i18n)
          }

          setNumbers(['', '', '', '', '', ''])
          setTimeout(() => {
            itemsRef.current[0]?.focus()
          }, 20)
        })
        .finally(() => setLoading(false))
    }, 1000)
  }

  const onResendVerificationCode = () => {
    setResendLoading(true)
    createVerification({
      email: searchParams.get('email')!,
    })
      .then((_) => {
        setResendLoading(false)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.emailResent')}
            description={t('toast.description.verificationCodeResent')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const result = numbers
    if (e.target.value.length === 0 || !e.target.value) {
      result[index] = ''
    } else if (e.target.value.length === 1) {
      result[index] = e.target.value.charAt(0)

      if (index + 1 < itemsRef.current.length) {
        itemsRef.current[index + 1]?.focus()
      } else {
        // itemsRef.current[numbers.length - 1]?.focus()
        onSubmit()
      }
    }
    if (e.target.value.length === 2) {
      if (index + 1 < numbers.length) {
        result[index] = e.target.value.charAt(0)
        result[index + 1] = e.target.value.charAt(1)

        if (index + 2 < itemsRef.current.length) {
          itemsRef.current[index + 2]?.focus()
        } else {
          // itemsRef.current[numbers.length - 1]?.focus()
          onSubmit()
        }
      }
    }

    setNumbers([...result])
  }

  const onPaste = (event: ClipboardEvent<HTMLInputElement>, index: number) => {
    event.preventDefault()

    const text = event.clipboardData.getData('text')
    const values = text
      .replace(/[^0-9.]/g, '')
      .substring(0, numbers.length - index)

    for (let i = 0; i < values.length; i++) {
      const char = values.charAt(i)
      if (char) {
        numbers[i + index] = char
      }
    }

    if (index + values.length < numbers.length) {
      itemsRef.current[index + values.length]?.focus()
    } else {
      onSubmit()
    }

    setNumbers([...numbers])
  }

  return (
    <>
      {searchParams.get('email') ? (
        <div className="space-y-10">
          <div>
            {false && (
              <img
                className="h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt={t('common.magicformsLogo')!}
              />
            )}
            <SparklesIcon className="h-10 w-10 text-indigo-600" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {t('page.title.verify')}
            </h2>
            <p
              className="mt-2 text-base leading-6 text-gray-500"
              dangerouslySetInnerHTML={{
                __html: t('page.description.verify', {
                  email: searchParams.get('email'),
                }),
              }}
            />
          </div>

          <VerticalAlignment>
            <FormContainer>
              <div className="flex space-x-3">
                {[0, 1, 2, 3, 4, 5].map((i) => (
                  <input
                    key={i}
                    ref={(el) => (itemsRef.current[i] = el)}
                    type="text"
                    autoComplete="given-name"
                    className="block font-semibold text-sky-600 text-center w-full max-w-lg rounded-md border-gray-300 disabled:border-gray-300/50 bg-gray-50 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs text-lg"
                    aria-label={`Digit ${i}`}
                    autoFocus={i === numbers.filter((x) => x !== '').length}
                    value={numbers[i]}
                    maxLength={2}
                    disabled={loading}
                    onKeyDown={(event) => {
                      if (
                        !/[0-9]/.test(event.key) &&
                        !(
                          event.key === 'x' ||
                          event.key === 'c' ||
                          (event.key === 'v' &&
                            (event.ctrlKey || event.metaKey))
                        ) &&
                        event.key !== 'Backspace' &&
                        event.key !== 'Tab' &&
                        event.key !== 'ArrowLeft' &&
                        event.key !== 'ArrowRight'
                      ) {
                        console.log('prevent default')
                        event.preventDefault()
                      }
                      if (event.key === 'Backspace' && !numbers[i]) {
                        // event.preventDefault()
                        itemsRef.current[i - 1]?.focus()
                      }
                    }}
                    // onKeyPress={(event) => {
                    //   if (
                    //     !/[0-9]/.test(event.key) &&
                    //     !(
                    //       event.key === 'v' &&
                    //       (event.ctrlKey || event.metaKey)
                    //     )
                    //   ) {
                    //     console.log('prevent default')
                    //     event.preventDefault()
                    //   }
                    // }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onChange(event, i)
                    }
                    onPaste={(event: ClipboardEvent<HTMLInputElement>) =>
                      onPaste(event, i)
                    }
                  />
                ))}
              </div>
              <p className="text-sm leading-6 text-gray-500">
                {t('form.description.verificationCode')}
              </p>
            </FormContainer>
            <Button
              variant="primary"
              type="submit"
              className="w-full"
              loading={loading}
            >
              {t('action.verifyCode')}
            </Button>
          </VerticalAlignment>

          <div className="text-sm leading-6 text-gray-500">
            <div>
              {t('description.noEmailReceived')}
              <ul className="pl-4 list-disc">
                <li>{t('description.waitAFewMinutes')}</li>
                <li>{t('description.lookInYourSpam')}</li>
                <li>
                  <button
                    className="font-medium text-indigo-600 hover:text-indigo-700 disabled:text-gray-400 disabled:cursor-not-allowed"
                    onClick={onResendVerificationCode}
                    disabled={resendLoading}
                  >
                    {t('action.resendVerificationCode')}
                  </button>{' '}
                  (<strong>{searchParams.get('email')!}</strong>)
                </li>
                <li>
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-700"
                    href="mailto:support@resflow.com"
                    onClick={() => {}}
                  >
                    {t('action.contactSupport')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/sign-in" replace />
      )}
    </>
  )
}

export default VerifyPage
