import React from 'react'
import UserResponse from 'src/business/dto/responses/user.response'

interface AuthContextType {
  user: UserResponse
  setUser: React.Dispatch<React.SetStateAction<UserResponse>>
  signOut: () => void
  impersonated: boolean
}

const AuthContext = React.createContext<AuthContextType>(null!)
export default AuthContext
