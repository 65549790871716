import { Outlet } from 'react-router-dom'

export default function PublicLayout() {
  return (
    <>
      <div className="flex min-h-full flex-1 bg-white">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <Outlet />
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <div className="absolute inset-4">
            <img
              className="h-full w-full rounded-3xl object-cover"
              src="/img/yolo.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}
