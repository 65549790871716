import Button from 'src/components/ui-kit/Button'
import Select from 'src/components/ui-kit/Select'
import FormContainer from 'src/components/ui-kit/FormContainer'
import Input from 'src/components/ui-kit/Input'
import AvatarUpload from 'src/components/ui-kit/AvatarUpload'
import Card from 'src/components/ui-kit/Card'
import CardContainer from 'src/components/ui-kit/CardContainer'
import VerticalAlignment from 'src/components/ui-kit/VerticalAlignment'
import useAuth from 'src/contexts/use-auth'

export default function AccountDetailsPage() {
  const auth = useAuth()

  return (
    <CardContainer>
      <Card
        title="Update your account"
        description="Change the email address you want associated with your account."
      >
        <form>
          <VerticalAlignment>
            <FormContainer>
              <AvatarUpload
                label="Photo"
                src="/img/avatar.jpeg"
                button="Change"
              />
              <Input
                name="name"
                type="text"
                label="Name"
                defaultValue={auth.user.name}
              />
              <Input
                name="email"
                type="email"
                label="Email"
                defaultValue={auth.user.email}
              />
            </FormContainer>
            <Button variant="primary">Save changes</Button>
          </VerticalAlignment>
        </form>
      </Card>
      <Card
        title="Change Language"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam."
      >
        <FormContainer>
          <Select
            label="Language"
            options={['Deutsch', 'English']}
            defaultValue={'English'}
          />
        </FormContainer>
      </Card>
      <Card
        title="Danger Zone"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam."
      >
        <Button variant="danger">Delete Account</Button>
        <Button variant="secondary" onClick={auth.signOut}>
          Logout
        </Button>
      </Card>
    </CardContainer>
  )
}
