import { SparklesIcon } from '@heroicons/react/24/solid'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { signIn } from 'src/business/api/auth.service'
import SignInRequest from 'src/business/dto/requests/sign-in.request'
import { handleValidationError } from 'src/business/error-handler'
import { updatePageTitle } from 'src/business/utils'
import Button from 'src/components/ui-kit/Button'
import FormContainer from 'src/components/ui-kit/FormContainer'
import Input from 'src/components/ui-kit/Input'
import UiToast from 'src/components/ui-kit/UiToast'
import VerticalAlignment from 'src/components/ui-kit/VerticalAlignment'

export default function SignInPage() {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect') || '/'
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<SignInRequest>({
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const [formEmail] = form.watch(['email'])

  useEffect(() => {
    updatePageTitle(t('page.title.signIn'))
  }, [])

  const onSubmit: SubmitHandler<SignInRequest> = (data) => {
    setLoading(true)
    signIn(data)
      .then((response) => {
        localStorage.setItem('token_type', response.tokenType)
        localStorage.setItem('access_token', response.accessToken)
        localStorage.setItem('refresh_token', response.refreshToken)
        navigate(redirect, { replace: true })
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.signedIn')}
            description={t('toast.description.signedIn')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        console.log('err', err)
        if (err.response?.status === 400) {
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type="error"
              title={t('toast.title.signedInError')}
              description={t('toast.description.invalidCredentials')}
            />
          ))
        } else if (err.response?.status === 403) {
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type="error"
              title={t('toast.title.signedInError')}
              description={t('toast.description.noSubscription')}
            />
          ))
        } else if (err.response?.status === 420) {
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type="error"
              title={t('toast.title.signedInError')}
              description={t('toast.description.notVerified')}
            />
          ))
          navigate({
            pathname: '/verify',
            search: `?email=${encodeURIComponent(formEmail)}`,
          })
        } else {
          handleValidationError(err, i18n)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="space-y-10">
      <div>
        {false && (
          <img
            className="h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
        )}
        <SparklesIcon className="h-10 w-10 text-indigo-600" />
        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {t('page.title.signIn')}
        </h2>
        <p className="mt-2 text-base leading-6 text-gray-500">
          {t('page.description.signIn')}
        </p>
      </div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <VerticalAlignment>
          <FormContainer>
            <Input
              label={t('common.emailAddress')}
              type="text"
              autoComplete="email"
              autoFocus
              error={form.formState.errors.email}
              {...form.register('email', {
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t('form.validation.emailAddress'),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              })}
            />
            <Input
              label={t('common.password')}
              type="password"
              autoComplete="password"
              error={form.formState.errors.password}
              {...form.register('password', {
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                minLength: {
                  value: 8,
                  message: t('form.validation.minLength', { x: 8 }),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              })}
            />
          </FormContainer>
          <div className="flex items-center justify-between">
            {/* <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="remember-me"
                className="ml-3 block text-sm leading-6 text-gray-700"
              >
                Remember me
              </label>
            </div> */}
            <div className="text-sm leading-6">
              <Link
                to="/forgot-password"
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                {t('action.forgotPassword')}
              </Link>
            </div>
          </div>
          <Button
            variant="primary"
            type="submit"
            className="w-full"
            loading={loading}
          >
            {t('action.signIn')}
          </Button>
        </VerticalAlignment>
      </form>
      <div className="pt-6 text-center border-t border-gray-200">
        <p className="text-sm leading-6 text-gray-500">
          <span>{t('description.dontHaveAnAccount')} </span>
          <Link
            to="/sign-up"
            className="font-medium text-indigo-600 hover:text-indigo-700"
          >
            {t('action.goToSignUp')}
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </p>
      </div>
    </div>
  )
}
