import { UserCircleIcon } from '@heroicons/react/24/solid'

type Props = {
  label: string
  src: string
  button: string
}

export default function AvatarUpload(props: Props) {
  return (
    <div>
      <label
        htmlFor="photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.label}
      </label>
      <div className="mt-2 flex items-center gap-x-3">
        {props.src && (
          <img
            src={props.src}
            className="h-10 w-10 rounded-full"
            aria-hidden="true"
            alt="image of user"
          />
        )}
        {!props.src && (
          <UserCircleIcon
            className="h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
        )}
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          {props.button}
        </button>
      </div>
    </div>
  )
}
