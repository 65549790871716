import Header from '../components/Header'
import { Outlet } from 'react-router-dom'
import Tabs from 'src/components/ui-kit/Tabs'

export default function FormPage() {
  return (
    <>
      <Header
        title="Corenox Projektanfrage"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean turpis mi, rhoncus nec dapibus in, rutrum at leo."
        breadcrumbs={[
          { to: '/projects', title: 'Projects' },
          { to: '/projects/1', title: 'Corenox' },
        ]}
      />
      <Tabs
        tabs={[
          { title: 'Pages', to: '/projects/1/forms/1' },
          {
            title: 'Appearance',
            to: '/projects/1/forms/1/appearance',
          },
          {
            title: 'Integrations',
            to: '/projects/1/forms/1/account/integrations',
          },
          {
            title: 'Settings',
            to: '/projects/1/forms/1/account/settings',
          },
        ]}
      />
      <Outlet />
    </>
  )
}
