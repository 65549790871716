import clsx from 'clsx'
import { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id'> & {
  label: string
  name: string
  error?: FieldError | undefined
}

const Input = forwardRef(function (props: Props, ref: any) {
  const { type, label, name, error, ...other } = props

  return (
    <>
      <div>
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <div className="mt-2">
          <input
            ref={ref}
            type={type || 'text'}
            id={name}
            name={name}
            className={clsx(
              'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
              error
                ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600'
            )}
            {...other}
          />
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
            {error.message}
          </p>
        )}
      </div>

      {/* <div>
        <div
          className={clsx(
            'relative rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset focus-within:ring-2 ',
            error
              ? 'ring-red-300 focus:ring-red-500'
              : 'ring-gray-300 focus-within:ring-indigo-600'
          )}
        >
          <label
            htmlFor={props.id}
            className={clsx(
              'block text-xs font-medium',
              error ? 'text-gray-900' : 'text-gray-900'
            )}
          >
            {props.label}
          </label>
          <input
            {...props}
            className={clsx(
              'block w-full border-0 p-0 focus:ring-0 sm:text-sm sm:leading-6 pr-8',
              error
                ? 'text-red-900 placeholder:text-red-300'
                : 'text-gray-900 placeholder:text-gray-400'
            )}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        </div>
        <p className="mt-2 text-sm text-red-600" id="email-error">
          Not a valid email address.
        </p>
      </div> */}
      {/* <div>
        <div className="relative">
          <label
            htmlFor={props.id}
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            {props.label}
          </label>
          <input
            {...props}
            className={clsx(
              'block w-full rounded-md border-0 py-2.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
              error
                ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600'
            )}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        </div>
        <p className="mt-2 text-sm text-red-600" id="email-error">
          Not a valid email address.
        </p>
      </div> */}
      {/* <div>
        <label
          htmlFor={props.id}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
        </label>
        <div className="relative mt-2">
          <input
            {...props}
            className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
          />
          <div
            className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-indigo-600"
            aria-hidden="true"
          />
        </div>
      </div> */}
    </>
  )
})

export default Input
