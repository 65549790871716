import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './i18n'
import reportWebVitals from './reportWebVitals'
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from 'react-router-dom'
import ProjectPage, { loader as projectLoader } from './pages/projects/Project'
import SubmissionsPage from './pages/Submissions'
import InternalLayout from './components/InternalLayout'
import SubmissionPage from './pages/Submission'
import FormPage from './pages/Form'
import DashboardPage from './pages/Dashboard'
import AccountPage from './pages/Account'
import AccountDetailsPage from './pages/AccountDetails'
import HelpPage from './pages/Help'
import AccountBillingPage, {
  accountBillingLoader,
} from './pages/AccountBilling'
import ProjectsPage, {
  loader as projectsLoader,
  action as projectsAction,
} from './pages/projects/Projects'
import ProjectFormsPage from './pages/ProjectForms'
import ProjectUsersPage from './pages/ProjectUsers'
import ProjectSettingsPage from './pages/ProjectSettings'
import AccountSecurityPage from './pages/AccountSecurity'
import FormPagesPage from './pages/FormPages'
import ErrorPage from './error-page'
import SignInPage from './pages/auth/SignIn'
import AuthProvider, { authLoader } from './components/AuthProvider'
import PublicLayout from './components/PublicLayout'
import axios, { AxiosError } from 'axios'
import { obtainToken } from './business/api/auth.service'
import { Toaster } from 'react-hot-toast'
import SignUpPage from './pages/auth/SignUp'
import VerifyPage from './pages/auth/Verify'
import ForgotPasswordPage from './pages/auth/ForgotPassword'
import ResetPasswordPage from './pages/auth/ResetPassword'

const date = new Date()
console.log('date', date)
console.log('date time', date.getTime())

const redirectToSignIn = () => {
  localStorage.removeItem('token_type')
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('magicforms_staff_token_type')
  localStorage.removeItem('magicforms_staff_access_token')
  localStorage.removeItem('magicforms_staff_refresh_token')

  throw redirect(
    `/sign-in${
      window.location.pathname !== '' && window.location.pathname !== '/'
        ? `?redirect=${window.location.pathname}`
        : ''
    }`
  )
}

const convertEmptyStringsToNull = (object: any) => {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      if (typeof object[key] === 'object' && object[key] !== null) {
        convertEmptyStringsToNull(object[key]) // Rekursiv für verschachtelte Objekte aufrufen
      } else if (object[key] === '') {
        object[key] = null // Leere Zeichenketten in null umwandeln
      }
    }
  }
}

axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token')
    if (config.headers && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
      config.headers['Content-Type'] = 'application/json'
    }

    if (config.data) {
      convertEmptyStringsToNull(config.data)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (originalRequest.url === '/auth/token') {
      // refresh token is invalid, not there, whatever
      redirectToSignIn()
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      // first time unauthorized request sent
      originalRequest._retry = true

      // try to use refresh token to get a new access token
      const refreshToken = localStorage.getItem('refresh_token')
      if (refreshToken) {
        return obtainToken({ refreshToken })
          .then((response) => {
            localStorage.setItem('token_type', response.tokenType)
            localStorage.setItem('access_token', response.accessToken)
            localStorage.setItem('refresh_token', response.refreshToken)
            console.log('resent request')
            return axios(originalRequest)
          })
          .catch((error: AxiosError) => {
            // other error than 404
            return Promise.reject(error)
          })
      } else {
        // no refresh token... redirect to sign in
        redirectToSignIn()
      }
    }

    return Promise.reject(error)
  }
)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <>
    <Toaster position="bottom-center" />
    <RouterProvider
      router={createBrowserRouter([
        {
          path: '/',
          element: <PublicLayout />,
          errorElement: <ErrorPage />,
          children: [
            {
              path: 'sign-in',
              element: <SignInPage />,
            },
            {
              path: 'sign-up',
              element: <SignUpPage />,
            },
            {
              path: 'verify',
              element: <VerifyPage />,
            },
            {
              path: 'forgot-password',
              element: <ForgotPasswordPage />,
            },
            { path: 'reset-password', element: <ResetPasswordPage /> },
          ],
        },
        {
          path: '/',
          element: (
            <AuthProvider>
              <InternalLayout />
            </AuthProvider>
          ),
          errorElement: <ErrorPage />,
          loader: authLoader,
          children: [
            {
              index: true,
              element: <DashboardPage />,
              // loader: teamLoader,
            },
            {
              path: 'submissions',
              element: <SubmissionsPage />,
              // loader: teamLoader,
            },
            {
              path: 'submissions/:submissionId',
              element: <SubmissionPage />,
              // loader: teamLoader,
            },
            {
              path: 'projects',
              element: <ProjectsPage />,
              loader: projectsLoader,
              action: projectsAction,
            },
            {
              path: 'projects/:projectId',
              element: <ProjectPage />,
              loader: projectLoader,
              children: [
                {
                  index: true,
                  element: <Navigate to="forms" replace />,
                },
                {
                  path: 'forms',
                  element: <ProjectFormsPage />,
                  // loader: teamLoader,
                },
                {
                  path: 'users',
                  element: <ProjectUsersPage />,
                  // loader: teamLoader,
                },
                {
                  path: 'settings',
                  element: <ProjectSettingsPage />,
                  // loader: teamLoader,
                },
              ],
              // loader: teamLoader,
            },
            {
              path: 'projects/:projectId/forms/:formId',
              element: <FormPage />,
              // loader: teamLoader,
              children: [
                {
                  index: true,
                  element: <FormPagesPage />,
                },
              ],
            },
            {
              path: 'help',
              element: <HelpPage />,
              // loader: teamLoader,
            },
            {
              path: 'account',
              element: <AccountPage />,
              // loader: teamLoader,
              children: [
                {
                  index: true,
                  element: <AccountDetailsPage />,
                  // loader: teamLoader,
                },
                {
                  path: 'security',
                  element: <AccountSecurityPage />,
                  // loader: teamLoader,
                },
                {
                  path: 'billing',
                  element: <AccountBillingPage />,
                  loader: accountBillingLoader,
                },
              ],
            },
          ],
        },
      ])}
    />
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
