import Header from '../../components/Header'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { CubeTransparentIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Link, LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import { Project } from 'src/business/dto/project'
import NewProjectModal from 'src/modals/NewProject'

export async function loader() {
  // const projects = await getProjects()
  // return { projects }
}

export async function action({ request, params }: LoaderFunctionArgs) {
  const formData = await request.formData()
  const creates = Object.fromEntries(formData)
  // const project = await createProject({ name: creates.name?.toString() })
  // return { project }

  // return redirect(`/contacts/${params.contactId}`)
  // const project = await createProject()
  // return { project }
}

export default function ProjectsPage() {
  const { projects } = useLoaderData() as { projects: Project[] }
  const [open, setOpen] = useState(false)

  return (
    <>
      <Header
        title="Projects"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean turpis mi, rhoncus nec dapibus in, rutrum at leo."
      />
      <hr className="my-6" />
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4"
      >
        {projects.map((project: Project) => (
          <li
            key={project.name}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white hover:bg-gray-50 shadow"
          >
            <Link
              to={'/projects/1'}
              className="flex w-full items-center justify-between space-x-6 p-6"
            >
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    {project.name}
                  </h3>
                  <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {project.name}
                  </span>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                  {project.name}
                </p>
              </div>
              {false && (
                <img
                  className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                  src={project.name}
                  alt=""
                />
              )}
              <div className="flex -space-x-2 overflow-hidden">
                <img
                  className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                  src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <img
                  className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                  src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <img
                  className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                  src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                  alt=""
                />
                <img
                  className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </div>
            </Link>
            {false && (
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    <a
                      href={`mailto:${project.name}`}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <EnvelopeIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Email
                    </a>
                  </div>
                  <div className="-ml-px flex w-0 flex-1">
                    <a
                      href={`tel:${project.name}`}
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <PhoneIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Call
                    </a>
                  </div>
                </div>
              </div>
            )}
          </li>
        ))}
        <li>
          <button
            type="button"
            className="relative block w-full h-full rounded-lg border-2 border-dashed border-gray-300 px-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => setOpen(true)}
          >
            <CubeTransparentIcon className="mx-auto h-12 w-12 text-gray-400" />
            <span className="mt-2 block text-sm font-semibold text-gray-900">
              Create a new project
            </span>
          </button>
        </li>
      </ul>

      {false && (
        <>
          <div className="md:flex md:items-center md:justify-between md:space-x-5">
            <div className="flex items-start space-x-5">
              {false && (
                <div className="flex-shrink-0">
                  <div className="relative">
                    <img
                      className="h-16 w-16 rounded-full"
                      src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                      alt=""
                    />
                    <span
                      className="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              )}
              {/*
          Use vertical padding to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
              <div className="pt-1.5">
                <h1 className="text-2xl font-bold text-gray-900">Forms</h1>
                <p className="text-sm font-medium text-gray-500">
                  Here you can create awesome forms for your website!
                </p>
              </div>
            </div>
            {false && (
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Disqualify
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Advance to offer
                </button>
              </div>
            )}
          </div>
        </>
      )}

      <NewProjectModal open={open} setOpen={setOpen} />
    </>
  )
}
