import CardContainer from 'src/components/ui-kit/CardContainer'
import Header from '../components/Header'
import Card from 'src/components/ui-kit/Card'
import Button from 'src/components/ui-kit/Button'

export default function HelpPage() {
  return (
    <>
      <Header
        title="Help"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean turpis mi, rhoncus nec dapibus in, rutrum at leo."
      />
      <hr className="my-6" />
      <CardContainer>
        <Card
          title="Help Center"
          description="Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Recusandae voluptatibus corrupti atque repudiandae nam."
        >
          <Button variant="primary">Help Center</Button>
        </Card>
        <Card
          title="Contact support"
          description="Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Recusandae voluptatibus corrupti atque repudiandae nam."
        >
          <Button variant="secondary">Contact Support</Button>
        </Card>
        <Card
          title="Feature Request"
          description="Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Recusandae voluptatibus corrupti atque repudiandae nam."
        >
          <Button variant="secondary">Feature Request</Button>
        </Card>
      </CardContainer>
    </>
  )
}
