import { AxiosError } from 'axios'
import clsx from 'clsx'
import { format, toZonedTime } from 'date-fns-tz'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'
import {
  createCheckout,
  createCompanyPortal,
  findQuota,
} from 'src/business/api/auth.service'
import { getCurrentFnsLocale } from 'src/business/date-utils'
import Quota from 'src/business/dto/responses/quota'
import ProductType from 'src/business/dto/types/product-type'
import SubscriptionStatus from 'src/business/dto/types/subscription-status'
import { handleValidationError } from 'src/business/error-handler'
import Button from 'src/components/ui-kit/Button'
import Card from 'src/components/ui-kit/Card'
import CardContainer from 'src/components/ui-kit/CardContainer'
import VerticalAlignment from 'src/components/ui-kit/VerticalAlignment'
import useAuth from 'src/contexts/use-auth'

export const accountBillingLoader = async () => findQuota()

export default function AccountBillingPage() {
  const { maxQuota } = useLoaderData() as { maxQuota: number }
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)

  const score = auth.user.quotaUsed / maxQuota
  const numberFormatter = new Intl.NumberFormat(auth.user.language)

  return (
    <CardContainer>
      {auth.user.subscriptions.map((subscription) => (
        <Card title="Contract Portal" description="This is the customer portal">
          <VerticalAlignment>
            <dl className="grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2">
              <div className="flex flex-col justify-center items-center bg-gray-100 py-8 px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  Monthly Submissions
                </dt>
                <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">
                  <span
                    className={clsx(
                      0.2 <= 0.8 && 'text-emerald-600',
                      0.2 <= 1 && 'text-amber-500',
                      0.2 > 1 && 'text-rose-600'
                    )}
                  >
                    {score}
                  </span>
                  <span> / </span>
                  <span>
                    {maxQuota === -1 ? '∞' : numberFormatter.format(maxQuota)}
                  </span>
                </dd>
              </div>
              <div className="flex flex-col justify-center items-center bg-gray-100 py-8 px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  Usage Reset
                </dt>
                <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">
                  {format(
                    toZonedTime(
                      new Date(subscription.currentPeriodEnd),
                      auth.user.timeZone
                    ),
                    'PP',
                    {
                      locale: getCurrentFnsLocale(),
                    }
                  )}
                </dd>
              </div>
              <div className="flex flex-col justify-center items-center bg-gray-100 py-8 px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  Selected Plan
                </dt>
                <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">
                  {t(`product.${subscription.productId}`)}
                </dd>
              </div>
              <div className="flex flex-col justify-center items-center bg-gray-100 py-8 px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  Current Status
                </dt>
                <dd
                  className={clsx(
                    'order-first text-2xl font-semibold tracking-tight',
                    subscription.cancelAtDateTimeInUtc === null &&
                      subscription.status === SubscriptionStatus.active
                      ? 'text-emerald-600'
                      : subscription.cancelAtDateTimeInUtc !== null ||
                        subscription.status === SubscriptionStatus.canceled
                      ? 'text-rose-600'
                      : 'text-amber-500'
                  )}
                >
                  {subscription.cancelAtDateTimeInUtc
                    ? t(`subscription.canceled`)
                    : t(`subscription.${subscription.status}`)}
                </dd>
              </div>
            </dl>
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  setLoading(true)
                  createCompanyPortal({
                    redirectUrl: window.location.href,
                  })
                    .then((x) => {
                      setTimeout(() => {
                        setLoading(false)
                      }, 600)
                      window.location.href = x.url
                    })
                    .catch((err: AxiosError) => {
                      setLoading(false)
                      handleValidationError(err, i18n)
                    })
                }}
              >
                Manage Subscription
              </Button>
              <p className="mt-2 text-xs text-gray-500">
                <span>Powered by </span>
                <a
                  className={`text-gray-600 hover:text-gray-500 font-medium`}
                  href="https://stripe.com"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  stripe
                </a>
                <span>.</span>
              </p>
            </div>
          </VerticalAlignment>
        </Card>
      ))}
      {auth.user.subscriptions.length === 0 && (
        <Card title="Buy a Subscription" description="This is the checkout">
          <VerticalAlignment>
            <Button
              variant="primary"
              onClick={() => {
                createCheckout({
                  redirectUrl: window.location.href,
                  productType: ProductType.magicformsBasic,
                }).then((x) => {
                  window.location.href = x.url
                })
              }}
            >
              Buy MagicForms Basic
            </Button>
          </VerticalAlignment>
        </Card>
      )}
    </CardContainer>
  )
}
